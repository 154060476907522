import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import reducer from '../reducers/home.reducer';
import rootSaga from '../sagas';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
// mount it on the Store

const composeEnhancers = composeWithDevTools( {
    trace: true,
} );

export default createStore(
    reducer,
    composeEnhancers( applyMiddleware( sagaMiddleware ) )
);

// then run the root saga
sagaMiddleware.run( rootSaga );
