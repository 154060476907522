import React from 'react';
import styles from './scss/home.module.scss';
import Tile from './tile.component';
import connect from './home.connect';
import PropTypes from 'prop-types';

/**
 * @param {object} props the props for the component
 * @returns {string} jsx/html code for home component
 */
function Home( props ) {
    const tiles = props.products.map( ( product ) => {
        return <Tile { ...product } key={ product.name } />;
    } );

    return (
        <section className={ styles.portal }>
            <h1 data-cy="home-heading" className={ styles.portalTitle }>
                Your Apps
            </h1>

            <ul data-cy="home-menu" className={ styles.portalNav }>
                { tiles }
            </ul>
        </section>
    );
}

Home.propTypes = {
    /*
     * List of products
     */
    products: PropTypes.array,
};

export default connect( Home );
