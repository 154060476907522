import './set-public-path';
import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import store from '../state/store';
import Home from './components/home.component';

const history = createBrowserHistory();
window.tgHistory = history;

/**
 * @param {object} props the props for the component
 * @returns {string} jsx/html code for home component
 */
export default function Root( props ) {
    return (
        <BrowserRouter>
            <Provider store={ store }>
                <Route
                    path="/"
                    exact
                    render={ () => <Home routes={ props.applicationRoutes } /> }
                />
            </Provider>
        </BrowserRouter>
    );
}

// type validation
Root.propTypes = {
    /**
     * Array of objects used to construct the nav items
     */
    applicationRoutes: PropTypes.arrayOf(
        PropTypes.shape( {
            text: PropTypes.string,
            pathname: PropTypes.string,
        } )
    ),
};
