import React from 'react';
import styles from './scss/tile.module.scss';
import PropTypes from 'prop-types';
import defaultLogo from '../../assets/default.svg';

/**
 * @param {object} props the props for the component
 * @returns {string} jsx/html code for home component
 */
export default function Tile( props ) {
    const { name, description, /*logo,*/ link } = props;
    
    // TODO: replace defaultLogo with dynamic URL from API when available through props
    return (
        <li className={ `${styles.col3} ${styles.col2Wide}` }>
            <a href={ link } className={ styles.tile }>
                <img src={ defaultLogo } width="40" height="40"></img>
                <h2 className={ styles.tileName }>{ name }</h2>
                <p className={ styles.tileDescription }>{ description }</p>
            </a>
        </li>
    );
}

Tile.propTypes = {
    /*
     * Product name, tile title
     */
    name: PropTypes.string,
    /*
     * Short description of what the product does
     */
    description: PropTypes.string,
    /*
     * CTA for tile
     */
    link: PropTypes.string,
    /*
     * url to tile svg
     */
    logo: PropTypes.string,
};
