import { put, takeLatest } from 'redux-saga/effects';
import {
    fetchedProducts,
    fetchedProductsError,
    FETCH_PRODUCTS
} from '../actions/home.action';

/**
 *
 */
export function* getProducts() {
    try {
        const response = yield fetch(
            'https://localhost:9000/src/assets/data.json'
        ).then( ( resp ) => resp.json() );

        yield put( fetchedProducts( response ) );
    } catch ( error ) {
        yield put( fetchedProductsError( error ) );
    }
}

/**
 *
 */
export function* watchProductsSaga() {
    yield takeLatest( FETCH_PRODUCTS, getProducts );
}
